import _objectSpread from "/Users/yuqingying/Documents/ranJing/project/frontend/school-sport-test-h5-student/node_modules/@babel/runtime/helpers/esm/objectSpread2";
import { defineComponent, reactive, toRefs, onMounted } from 'vue';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';
import loginPwd from './components/login-pwd.vue';
export default defineComponent({
  props: {},
  components: {
    loginPwd: loginPwd
  },
  setup: function setup() {
    var store = useStore();
    var route = useRoute();
    var router = useRouter();
    var dataMap = reactive({
      loginType: '',
      isPwdLogin: true,
      schoolTitle: '',
      token: ''
    });
    dataMap.token = route.query.token;

    function getSchoolInfo() {
      store.dispatch('student/getSchoolInfo', {
        token: dataMap.token
      }).then(function (res) {
        if (res.code == 200) {
          dataMap.schoolTitle = res.data.title;
        }
      });
    }

    onMounted(function () {
      getSchoolInfo();
    });
    var refData = toRefs(dataMap);
    return _objectSpread({}, refData);
  }
});