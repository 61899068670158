import _objectSpread from "/Users/yuqingying/Documents/ranJing/project/frontend/school-sport-test-h5-student/node_modules/@babel/runtime/helpers/esm/objectSpread2";
import { defineComponent, reactive, toRefs } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { useStore } from '@/store';
export default defineComponent({
  props: {
    token: {
      type: String,
      default: ''
    }
  },
  components: {},
  setup: function setup(props) {
    var router = useRouter();
    var route = useRoute();
    var store = useStore();
    var dataMap = reactive({
      ruleForm: {
        username: '',
        number: '',
        token: props.token
      }
    });

    var onSubmit = function onSubmit(values) {
      store.dispatch('login/accountLoginAction', dataMap.ruleForm).then(function (res) {
        if (res.code == 200) {
          router.push('/studentDetail/' + dataMap.ruleForm.token + '/' + dataMap.ruleForm.number + '/' + dataMap.ruleForm.username);
        }
      });
    };

    var refData = toRefs(dataMap);
    return _objectSpread(_objectSpread({}, refData), {}, {
      onSubmit: onSubmit
    });
  }
});